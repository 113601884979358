var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","no-fade":""}},[_c('div',[_c('div',{staticClass:"todo-header"},[_c('div',{staticClass:"header-cell w-75"},[_vm._v("Name")]),_c('div',{staticClass:"header-cell w-20"},[_vm._v("Type")]),_c('div',{staticClass:"header-cell w-5"},[_vm._v("Actions")])]),_vm._l((_vm.tasks),function(task,index){return _c('div',{key:index},[_c('div',{staticClass:"task-content",on:{"dblclick":function($event){return _vm.nameEditingNew(index,task.name)}}},[_c('div',{staticClass:"todo-cell w-75 hover-background",staticStyle:{"border-left":"none","cursor":"pointer","transition":"background-color 0.2s"}},[(_vm.nameEditing==index)?_c('div',{staticClass:"task-name w-100"},[_c('div',{staticClass:"input-container w-100"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(task.name),expression:"task.name"}],staticClass:"task-name w-100",attrs:{"type":"text","rows":"1"},domProps:{"value":(task.name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.nameEdited(task)},"change":function($event){return _vm.updateTask()},"input":function($event){if($event.target.composing){ return; }_vm.$set(task, "name", $event.target.value)}}})])]):_c('div',{staticClass:"task-name"},[_c('div',{staticClass:"w-100 ml-2"},[_vm._v(_vm._s(task.name))])])]),_c('div',{staticClass:"todo-cell w-20 hover-background"},[_c('button',{staticStyle:{"border":"none","padding":"5px","cursor":"pointer","width":"100%"},style:({
                        backgroundColor: _vm.getSectionBgColor(task.type),
                        color: 'white',
                      }),attrs:{"id":'section-controls-' + task.id}},[_vm._v(" "+_vm._s(task.type || 'N/A')+" ")]),_c('b-popover',{attrs:{"target":'section-controls-' + task.id,"show":_vm.popoverShow[task.id],"triggers":"focus","variant":"primary","placement":"left"},on:{"update:show":function($event){return _vm.$set(_vm.popoverShow, task.id, $event)}}},[_c('div',{staticClass:"d-flex flex-column"},[_c('button',{staticClass:"mb-25",staticStyle:{"padding":"5px 25px","border":"none"},style:({
                        backgroundColor: _vm.getSectionBgColor('Document'),
                        color: 'white',
                      }),on:{"click":function($event){return _vm.updateType(task, 'Document')}}},[_vm._v("Document")]),_c('button',{staticClass:"mb-25",staticStyle:{"padding":"5px 25px","border":"none"},style:({
                        backgroundColor: _vm.getSectionBgColor('Activity'),
                        color: 'white',
                      }),on:{"click":function($event){return _vm.updateType(task, 'Activity')}}},[_vm._v("Activity")])])])],1),_c('div',{staticClass:"todo-cell w-5 hover-background"},[_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteOnboarding(task)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Remove")])],1)],1)],1)])])}),_c('div',{staticClass:"task-content add-cell"},[_c('div',{staticClass:"w-3"}),_c('div',{staticClass:"task-content add-cell",on:{"click":function($event){return _vm.addTask()}}},[_c('div',{staticClass:"w-3"}),_c('div',{staticClass:"w-97 d-flex justify-content-start"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fa-regular fa-plus mr-1"}),_c('span',[_vm._v("Add new task...")])])])])]),_c('div',{staticClass:"no-results",class:{ show: !_vm.tasks.length }},[_c('h5',[_vm._v("No Items Found")])])],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }