<template>
  <div>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>

        <div>
              
                <div class="todo-header">
                  <div class="header-cell w-75">Name</div>
                  <div class="header-cell w-20">Type</div>
                  <div class="header-cell w-5">Actions</div>
               
                </div>

                <div v-for="(task, index) in tasks" :key="index">
                  <div class="task-content" @dblclick="nameEditingNew(index,task.name)">

                    <div class="todo-cell w-75 hover-background" style="border-left:none; cursor: pointer; transition: background-color 0.2s;" >
                      <div class="task-name w-100" v-if="nameEditing==index">
                             
                        <div class="input-container w-100">
                        <textarea
                          @keyup.enter="nameEdited(task)"
                          type="text"
                          class="task-name w-100"
                          v-model="task.name"
                          rows="1"
                          @change="updateTask()"
                        >
                      </textarea>
                  </div>                                                                                                                                                                                                                                                                                                                                               

                      </div>
                      <div  class="task-name" v-else>

                        <div class="w-100 ml-2">{{ task.name }}</div>

                   
                      </div>

      

                    </div>
                  

                   

               

                    <div class="todo-cell w-20 hover-background">
                      <button
                      :style="{
                          backgroundColor: getSectionBgColor(task.type),
                          color: 'white',
                        }"

                        style=" border: none; padding: 5px; cursor: pointer; width:100%"
                        :id="'section-controls-' + task.id"
                      >
                        {{ task.type || 'N/A' }}
                      </button>
                      <b-popover :target="'section-controls-' + task.id" :show.sync="popoverShow[task.id]" triggers="focus" variant="primary" placement="left">
                        <div class="d-flex flex-column">
                          <button @click="updateType(task, 'Document')" class="mb-25" style="padding:5px 25px;  border:none"  :style="{
                          backgroundColor: getSectionBgColor('Document'),
                          color: 'white',
                        }">Document</button>
                          <button @click="updateType(task, 'Activity')" class="mb-25" style="padding:5px 25px; border:none"  :style="{
                          backgroundColor: getSectionBgColor('Activity'),
                          color: 'white',
                        }">Activity</button>
                       
                        </div>
                      </b-popover>
                    </div>

                    <div class="todo-cell w-5 hover-background">
                      <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="deleteOnboarding(task)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Remove</span>
              </b-dropdown-item>

              
            </b-dropdown>
                    </div>

                  </div>

                </div>

                <div class="task-content add-cell">
                  <div class="w-3"></div>

                  <div class="task-content add-cell" @click="addTask()">
                  <div class="w-3"></div>
                  <div class="w-97 d-flex justify-content-start">
                    <div class="d-flex align-items-center">
                      <i class="fa-regular fa-plus mr-1"></i>
                      <span>Add new task...</span>
                    </div>
                  </div>
                </div>

                </div>

                <div class="no-results" :class="{ show: !tasks.length }">
                  <h5>No Items Found</h5>
                </div>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios';
import { BTable } from 'bootstrap-vue';
import store from '@/store';
import settingsStoreModule from '../settingsStoreModule';
import { onUnmounted } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BTabs, BTab, BCardText } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    BTable,
    vSelect,

    ToastificationContent,
    BCardText,
    BTabs,
    BTab,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';

    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    const toast = useToast();
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },


    return {
      popoverShow:{},
      nameEditing:null,
      oldName : null,
      rolePerms: [],
      edit: null,
      newitem: '',
      filter: null,
      perPage: 10,
      tasks: [],
      permissions: [],
      currentPage: 1,
      perPageOptions: [5, 10, 15, 20],
      loading: false,

    };
  },
  computed: {
    dataMeta() {
      return {
        // from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        // to: perPage.value * (currentPage.value - 1) + localItemsCount,
        // of: totalUsers.value,
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
    currencyState() {
      return this.modalCurrencyCode != null && this.modalCurrencySymbol != null && this.modalCurrencyCode != '' && this.modalCurrencySymbol != '' ? true : false;
    },
  },
  methods: {
    getTasks() {
      this.loading = true;
      store
        .dispatch('settings/fetchOnboarding', [])
        .then((res) => {
          this.tasks = res.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },




    updateTask(val) {
      this.modalCurrencyId = val.id;
      this.modalCurrencyCode = val.code;
      this.modalCurrencySymbol = val.symbol;

    },

    nameEdited(val){


      store
        .dispatch('settings/updateOnboardingName', {
          name: val.name,
          oldName : this.oldName
        })
        .then((res) => {
          this.loading = false;
          this.oldName = null;
          this.nameEditing = null;

          if(res.data != 'Name Already Exists'){
            this.tasks = res.data
            this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Task Name has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          }
          else{
            this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: res.data,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });

          }

          
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });


      this.nameEditing=null
    },

    nameEditingNew(index,oldName){
      this.oldName = oldName;
      this.nameEditing = index;
    },

    updateType(task,type ) {
      if(task.name!=null){

        this.popoverShow[task.id] = false;
      this.loading = true;

      store
        .dispatch('settings/updateOnboardingType', {
          name: task.name,
          type: type,
        })
        .then((res) => {
          this.loading = false;

          if(res.data != 'Data could not be found'){
            this.tasks = res.data
            this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Task Type has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });

          }
          else{
            this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: res.data,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });

          }


          
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });

      }
    
    },

    deleteOnboarding(task) {
      if(task.id!=null){

     
      store
        .dispatch('settings/deleteOnboarding', {
          id: task.id,
        })
        .then((res) => {
          this.loading = false;

            this.tasks = res.data
            this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Task Type has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });

         

          
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });

      }
    
    },

    addTask() {

      let tempTask = {name:null,title:null};
      this.tasks.push(tempTask)
      
    },

    checkFormValidityCurrency() {
      const currencyValid = this.$refs.currencyRules.checkValidity();

      return currencyValid;
    },

    checkFormValidityCurrencyAdd() {
      const currencyValid = this.$refs.currencyAddRules.checkValidity();

      return currencyValid;
      
    },

    getSectionBgColor(val) {
      
      if(val == 'Activity'){
      return '#4CBB17';
      }
      else if(val == 'Document'){
        return '#C9CC3F';
      }
   
     }


  
  },
  created() {
    this.getTasks();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
.tab-class >>> .nav-item {
  position: relative;
  display: flex;
  justify-content: start;
}

.tab-class >>> .nav-vertical {
  padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
  width: 100%;
}
</style>



<style lang="scss" scoped>
span.activeMenu {
  font-weight: 700;
  color: black;
  padding-bottom: 2px;
  box-shadow: inset 0 -2px #6d6e6f;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.passiveMenu {
  font-weight: 400;
  color: #6d6e6f;
  padding-bottom: 2px;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.activeMenu:hover {
  background-color: #f9f8f8;
  padding: 5px 10px 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

span.passiveMenu:hover {
  background-color: #f9f8f8;
  box-shadow: inset 0 -2px #afabac;
  padding: 5px 10px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

.projectTitle {
  font-size: 1.75rem;
  font-weight: 400;
  transition: padding 0.3s;
  border: solid 1px transparent;
  border-radius: 5px;
}

.projectTitle:hover {
  border-color: #ccc;
  background-color: #f9f8f8;
  cursor: pointer;
}

.subTitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 500;
}

.person {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  min-width: 120px;
  max-width: 220px;
}

.person:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.description {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;
}

.description:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: #ccc;
}

.profile-photo {
  width: 42px;
  height: 42px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
}

.initials {
  text-transform: uppercase;
}

.person-details {
  flex-grow: 1;
}

.name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.position {
  color: #6d6e6f;
  margin-top: 10px;
}
.close-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  transition: opacity 0.3s;
}

.person:hover .close-icon {
  opacity: 1;
}

/* TO - DO   */

.todo-header {
  display: flex;
  background-color: #f5f5f5;
}

.header-cell {
  padding: 7px;
  border: 1px solid #e5e5e5;
  font-weight: bold;
}

.task-content {
  display: flex;
  width: 100%;
}

.subtask-content {
  display: flex;
  width: 100%;
  left: 30px;
}

.todo-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.add-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  background-color: #dddddd20;
}

.add-cell:hover {
  background-color: #dddddd40;
  cursor: pointer;
}

.todo-cell:last-child {
  border-right: 1px solid #e5e5e5;
}

.task-name {
  display: flex;
  align-items: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-checkbox {
  position: relative;
}

.custom-checkbox .checkbox-icon {
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
}

.custom-checkbox .checkbox-icon:not(.checked)::before {
  content: '\2713';
  font-size: 10px;
  color: #000;
}

.custom-checkbox .checkbox-icon.checked {
  background-color: #198754;
}

.custom-checkbox .checkbox-icon.checked::before {
  content: '\2713';
  font-size: 14px;
  color: #fff;
}

.no-results {
  text-align: center;
  padding: 20px;
  display: none;
}

.no-results.show {
  display: block;
}

.w-97 {
  width: 97%;
}

.w-47 {
  width: 47%;
}
.w-45 {
  width: 45%;
}

.w-42 {
  width: 42%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-37 {
  width: 37%;
}

.w-5 {
  width: 5%;
}

.w-4 {
  width: 4%;
}

.w-3 {
  width: 3%;
}



.w-75 {
  width: 75%;
}

.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}

.hover-background:hover {
  background-color: #edeae9;
  cursor: pointer;
  position: relative;
}

.hover-background:hover::after {
  content: '\23F7';
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: #bbb;
}

.popoverMenu {
  cursor: pointer;
  padding: 0.5rem;
}

.popoverMenu:hover {
  background-color: rgba(197, 197, 197, 0.05);
  padding: 0.5rem;
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #description-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.input-container {
  display: inline-block; /* Display the input container as inline-block */
  border: 1px solid #ccc; /* Add a border for styling */
  border-radius: 4px; /* Adjust border radius as needed */
  padding: 5px; /* Adjust padding as needed */
}

textarea {
  border: none; /* Remove the textarea border */
  resize: none; /* Disable textarea resizing */
  outline: none; /* Remove outline on focus */
  background: transparent; /* Make the background transparent */
  width: 100%; /* Expand the textarea to fill the container */
}

</style>
